import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { ProgressData } from "@/data";

const ProgressOne = () => {
  const { sectionImage, sectionContent, posts } = ProgressData;
  return (
    <section id="work" className="service-style-two about-page">
      <div className="overlay"/>
      <img
        src={sectionImage}
        className="background-right"
        alt="Awesome Image"
      />
      <div className="thm-container">
        <div className="row">
          <div className="col-lg-6">
            <div className="service-content">
              <span>{sectionContent.subText}</span>
              <h2>{sectionContent.title}</h2>
              <p>{sectionContent.content}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProgressOne;
