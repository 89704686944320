import React from "react";
import { AboutOneData } from "@/data";
import { Col, Row } from "react-bootstrap";
import AboutCard from "@/components/about-card";

const AboutOne = () => {
  return (
    <section className="about-section sec-pad">
      <div className="thm-container">
        <Row>
          {AboutOneData.map((post, index) => (
            <Col md={12} lg={4} key={index}>
              <AboutCard data={post} />
            </Col>
          ))}
        </Row>
          <section className="team-section sec-pad">
              <div className="thm-container">
              <div className="sec-title">
                  <span id="us">About Newby Enterprises  </span>
                  <h3>Your Trusted Sign Blank Provider </h3>
                  <p>We are proud to provide our valued customers with blank canvases to bring their visions to life.
                      From traffic and construction or oil & gas regulatory signs to custom projects and creative
                      pursuits, we offer high-quality blanks across an array of industries. <br/><br/>

                      Headquartered in Calgary, Alberta, we have been proudly serving our community and beyond since
                      1998. Whether you reach out with clear specifications or you require our guidance to satisfy your
                      industry’s standards, we will ensure you leave with exactly what you need.<br/><br/>

                      Our product roster is impressive and exhaustive. Whether it is a 3M laminated aluminum blank,
                      a baked white enamel, or degreased, we can meet
                      your needs. Our stock is always full of many different sizes; this allows us to maintain quick
                      fulfillment times on your orders and, in many cases, next-day shipping.
                  </p>
              </div>
              </div>
          </section>

      </div>
    </section>
  );
};

export default AboutOne;
